const colors = {
  primary: {
    white: '#FFFFFF',
    gray: '#161616',
    background: '#f5f5f5',
    black: '#2b2b2b',
    subtleText: '#656D76',
  },
  secondary: {
    darkGray1: '#2b2b2b',
    darkGray2: '#202020',
    lightGray1: '#f8f8f8',
    lightGray2: '#eaeaea',
    lightGray3: '#d7d7d7',
    mediumGray: '#8b8b8b',
    gold0: '#fff5d9',
    gold1: '#ffd979',
    gold2: '#ffc940',
    blue1: '#d7e1ff',
    blue2: '#245af0',
    blue3: '#002ab3',
  },
  dataType: {
    text: '#CFFFDA',
    markdown: '#eaeaea',
    image: '#DEE6FD',
    video: '#FDDEF6',
    audio: '#FFF1CF',
    tabular: '#ead7ff',
    binary: '#eaeaea',
    directory: '#eaeaea',
    dir: '#eaeaea',
  },
  data: {
    image: { dark: '#245af0', light: '#DEE6FD' },
    tabular: { dark: '#8250df', light: '#ead7ff' },
    video: { dark: '#ff6361', light: '#FDDEF6' },
    audio: { dark: '#ffa600', light: '#FFF1CF' },
    markdown: { dark: '#ff6361', light: '#eaeaea' },
    text: { dark: '#00A65A', light: '#CFFFDA' },
    binary: { dark: '#999999', light: '#eaeaea' },
    directory: { dark: '#eaeaea', light: '#eaeaea' },
    dir: { dark: '#eaeaea', light: '#eaeaea' },
  },
  semantic: {
    positive: {
      dark: '#00533F',
      light: '#E6FFE7',
      primary: '#00A65A',
    },
    notice: {
      dark: '#4B3E37',
      light: '#fff8c5',
      border: 'rgba(212,167,44,0.4)',
    },
    negative: {
      dark: '#950303',
      light: '#FFE8E8',
      primary: '#FF0000',
    },
    information: {
      dark: '#002BA3',
      light: '#EFF0FF',
      border: 'rgba(0, 43, 163, 0.4)',
      primary: '#002BA3',
    },
    white: {
      dark: '#2b2b2b',
      light: '#ffffff',
    },
    default: {
      dark: '#2b2b2b',
      light: '#eaeaea',
    },
    hover: '#E8E8E8',
    link: '#002ab3',
    mergeStatus: {
      open: '#00A65A',
      closed: '#FF0000',
      merged: '#8250df',
      conflict: '#950303',
    },
    placeholder: '#515151',
    disabled: '#C9C9C9',
    diff: {
      added: {
        background: '#E6FFE7',
        // text: 'rgb(17, 99, 41)',
        highlight: '#abf2bc',
        indicator: '#116329',
      },
      removed: {
        background: '#ffebe9',
        // text: 'rgb(31, 35, 40)',
        highlight: 'rgba(255,129,130,0.4)',
        indicator: '#cf222e',
      },
      deleted: {
        background: '#ffebe9',
        // text: 'rgb(31, 35, 40)',
        highlight: 'rgba(255,129,130,0.4)',
        indicator: '#cf222e',
      },
      modified: {
        // background: 'rgb(221, 244, 255)',
        // // text: 'rgb(31, 35, 40)',
        // highlight: 'rgba(84, 174, 255, 0.4)',
        // indicator: '#0550ae',

        background: '#fff5d9',
        highlight: '#ffd979',
        // indicator: '#ffc940',
        indicator: '#c08900',
      },
    },
  },
};

export default colors;
